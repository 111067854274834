import history from "./history";
import { BrowserRouter } from "react-router-dom";
import Layout from "components/layout";
import { Provider } from "react-redux";
import store from "./stores";

function App() {
  return (
    <Provider store={store({})}>
      <BrowserRouter history={history}>
        <Layout />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
