import { THEME_ENUM } from "constants/theme.constant"

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
    layout: {
        type: THEME_ENUM.LAYOUT_TYPE_BASIC
    },
}
